<template>
  <div>
    <v-container>
      <p>
        Identify which of the following condensed electron configurations is/are <b>invalid</b> for
        the ground state of an atom. Select all that apply.
      </p>
      <v-row v-for="option in shuffledOptions" :key="option.input" class="pl-2">
        <v-checkbox v-model="inputs[option.input]">
          <template #label>
            <stemble-latex :content="option.electronConfig" />
          </template>
        </v-checkbox>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'IdentifyIncorrectElectronConfigs',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      answer: null,
      inputs: {
        pair1Selected: null,
        pair2Selected: null,
        pair3Selected: null,
        pair4Selected: null,
      },
    };
  },
  computed: {
    shuffledOptions() {
      const seed = this.$gate.user?.id || 1;

      return seededShuffle(this.options, seed);
    },

    options() {
      const configurations = [
        //variables are just being used to pass data. Original symbols have no meaning
        {H: '$[\\ce{Ar}]3s^23p^4$', He: '$[\\ce{Ne}]2s^22p^5$'},
        {Li: '$[\\ce{He}]2s2^2p^2$', Be: '$[\\ce{Ne}]3s^23p^4$'},
        {B: '$[\\ce{Xe}]6s^24f^{14}5d^5$', C: '$[\\ce{Rn}]7s^25f^{14}6d^3$'},
        {N: '$[\\ce{Kr}]5s^25p^64d^3$', O: '$[\\ce{Ar}]4s^24p^63d^7$'},
      ];
      return ['pair1', 'pair2', 'pair3', 'pair4'].map((symbol, index) => {
        let configuration = this.taskState.getValueBySymbol(symbol).content;
        return {
          electronConfig: configurations[index][configuration.symbol],
          input: `${symbol}Selected`,
        };
      });
    },
  },
};
</script>
